@import "minima/skins/classic",
  "minima/initialize";

.post-nav-section {
  border-top: 1px solid $border-color-01;
  padding-top: 1.75rem;

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;

    @media (max-width: 480px) {
      .item {
        padding-top: 1rem;
      }
    }
  }

  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;

    &-left {
      width: 50%;
      padding-right: 1rem;
    }

    &-right {
      width: 50%;
      padding-left: 1rem;
    }
  }
}
